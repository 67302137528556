<template>
  <b-input-group :prepend="prefix" :append="suffixLocalize ? $tc(suffix, value) : suffix">
    <cleave
      class="form-control"
      :value="value"
      :options="number"
      :raw="true"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      @blur.native="blur"
    />
  </b-input-group>
</template>

<script>
import Cleave from "vue-cleave-component";
import { BInputGroup } from "bootstrap-vue";

export default {
  components: {
    Cleave,
    BInputGroup
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {
      default: "",
    },
    suffix: {
      default: "",
    },
    suffixLocalize: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
</style>
