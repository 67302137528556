export default [
  {
    path: '/order',
    name: 'list-order',
    component: () => import('@/views/order/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.order',
      resource: 'order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOrder',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order/:id',
    name: 'view-order',
    component: () => import('@/views/order/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.order',
      navActiveLink: 'list-order',
      resource: 'order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOrder',
          to: { name: 'list-order' }
        },
        {
          text: 'breadcrumb.orderDetail',
          active: true,
        },
      ],
    },
  },
]
