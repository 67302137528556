import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/agent`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  sync(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "sync",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "agent_code",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [],
        filtertype: payload.queryCondition ? payload.queryCondition : "AND",
      };
      payload.query.forEach(element => {
        filters.filters.push(element);
      });
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
  orderReport(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "order-report",
      },
    });
  },
  orderVolumeReport(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "order-volume-report",
      },
    });
  },
};
