export default [
  {
    path: '/call-plan',
    name: 'list-call-plan',
    component: () => import('@/views/callPlan/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.callPlan',
      resource: 'call-plan',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCallPlan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/call-plan/:id',
    name: 'view-call-plan',
    component: () => import('@/views/callPlan/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.callPlan',
      navActiveLink: 'list-call-plan',
      resource: 'call-plan',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCallPlan',
          to: { name: 'list-call-plan' }
        },
        {
          text: 'breadcrumb.callPlanDetail',
          active: true,
        },
      ],
    },
  },
]
