<template>
  <div>
    <div
      class="image-uploader flex-center-vertically text-center"
      style="min-height: 150px; height: auto; border: 1px dashed"
      :style="{
        borderColor: activeColor,
        cursor: readonly ? '' : 'pointer',
        backgroundColor: readonly ? secondary : '',
        width: fullWidth ? '' : `${pw}px`,
        height: fullWidth ? '' : `${ph}px`,
      }"
      ref="dragDropBox"
      @click="chooseImage"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div v-if="!value && !image">
        <feather-icon
          icon="ImageIcon"
          size="30"
          :style="{
            color: readonly ? '#ffffff' : activeColor,
          }"
        />
        <div
          class="mt-2"
          :style="{
            color: readonly ? '#ffffff' : activeColor,
          }"
        >
          {{ $t("button.clickOrDragDropToUpload") }}
        </div>
      </div>
      <div v-if="value || image">
        <img class="img-fluid" :src="value ? value : image" alt="" />
      </div>
    </div>
    <input
      type="file"
      style="display: none"
      multiple
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange"
      ref="fileInput"
      accept=".pdf,.jpg,.jpeg,.png"
    />
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";

export default {
  delimiters: ["${", "}"],

  data: function () {
    return {
      filelist: [],
    };
  },

  props: {
    image: {},
    value: {},
    size: {},
    pw: {},
    ph: {},
    fullWidth: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Image",
    },
    readonly: {},
  },
  
  methods: {
    onChange() {
      if (this.readonly) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$emit("input", e.target.result);
        this.$emit("change", e.target.result);
      };
      reader.readAsDataURL(this.filelist[0]);
    },
    remove() {
      this.filelist.splice(0, 1);
      this.$emit("input", null);
      this.$emit("change", null);
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseImage() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
