export default [
  {
    path: '/product',
    name: 'list-product',
    component: () => import('@/views/product/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.product',
      resource: 'product',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProduct',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/:id/update',
    name: 'update-product',
    component: () => import('@/views/product/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.product',
      navActiveLink: 'list-product',
      resource: 'product',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listProduct',
          to: { name: 'list-product' }
        },
        {
          text: 'breadcrumb.updateProduct',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/:id',
    name: 'view-product',
    component: () => import('@/views/product/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.productDetail',
      navActiveLink: 'list-product',
      resource: 'product',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProduct',
          to: { name: 'list-product' }
        },
        {
          text: 'breadcrumb.productDetail',
          active: true,
        },
      ],
    },
  },
]
