export default [
  {
    path: '/market-survey',
    name: 'list-market-survey',
    component: () => import('@/views/marketSurvey/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.marketSurvey',
      resource: 'market-survey',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listMarketSurvey',
          active: true,
        },
      ],
    },
  },
]
