export default [
  {
    path: '/activity',
    name: 'list-activity',
    component: () => import('@/views/activity/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.activity',
      resource: 'activity',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.activity',
          active: true,
        },
      ],
    },
  },
  {
    path: '/activity/:id',
    name: 'view-activity',
    component: () => import('@/views/activity/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.activityDetail',
      navActiveLink: 'list-activity',
      resource: 'activity',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.activity',
          to: { name: 'list-activity' }
        },
        {
          text: 'breadcrumb.activityDetail',
          active: true,
        },
      ],
    },
  },
]
