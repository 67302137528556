import Vue from "vue";
import moment from "moment";
import i18n from "@/libs/i18n";

Vue.filter("formatDate", function (value, format = "LLL") {
  if (!value) {
    return "";
  }
  if (value == "0001-01-01") {
    return "-";
  }
  return moment(value).locale(i18n.locale).format(format);
});

Vue.filter("formatShortDate", function (value, format) {
  if (!value) {
    return "";
  }

  return moment(value, format).locale(i18n.locale).calendar(null, {
    sameDay: "h:mm A",
    lastDay: "dddd h:mm A",
    lastWeek: "dddd h:mm A",
    sameYear: "LLL",
    sameElse: "LLL",
  });
});