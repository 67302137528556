export default [
  {
    path: '/department',
    name: 'list-department',
    component: () => import('@/views/department/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.department',
      resource: 'department',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listDepartment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/department/create',
    name: 'create-department',
    component: () => import('@/views/department/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.department',
      navActiveLink: 'list-department',
      resource: 'department',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listDepartment',
          to: { name: 'list-department' }
        },
        {
          text: 'breadcrumb.createDepartment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/department/:id/update',
    name: 'update-department',
    component: () => import('@/views/department/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.department',
      navActiveLink: 'list-department',
      resource: 'department',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listDepartment',
          to: { name: 'list-department' }
        },
        {
          text: 'breadcrumb.updateDepartment',
          active: true,
        },
      ],
    },
  },
]
