export default [
  {
    path: '/claimRequest',
    name: 'list-claim-request',
    component: () => import('@/views/claimRequest/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.claimRequest',
      resource: 'claim-request',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.claimRequest',
          active: true,
        },
      ],
    },
  },
]
