import Vue from 'vue';
import currency from 'currency.js'

Vue.filter('currency', function (value) {
    if (typeof value === 'undefined' || value == null) {
        return '';
    }
    let precision = 0;
    let money = value.toString().split(".");
    if (money.length > 1) {
        precision = money[1].length;
    }

    const USD = value => currency(value, {
        precision: precision
    });
    return USD(value).format();
});

Vue.filter('number', function (value) {
    if (typeof value === 'undefined' || !value) {
        return value;
    }
    let precision = 0;
    let money = value.toString().split(".");
    if (money.length > 1) {
        precision = money[1].length;
    }

    const USD = value => currency(value, {
        precision: precision,
        symbol: "",
    });
    return USD(value).format();
});
