export default [
  {
    path: '/payment-method',
    name: 'list-payment-method',
    component: () => import('@/views/paymentMethod/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.paymentMethod',
      resource: 'payment-method',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPaymentMethod',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-method/:id/update',
    name: 'update-payment-method',
    component: () => import('@/views/paymentMethod/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.paymentMethod',
      navActiveLink: 'list-payment-method',
      resource: 'payment-method',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPaymentMethod',
          to: { name: 'list-payment-method' }
        },
        {
          text: 'breadcrumb.updatePaymentMethod',
          active: true,
        },
      ],
    },
  },
]
