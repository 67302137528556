export default [
  {
    path: '/leave-request-detail',
    name: 'list-leave-request-detail',
    component: () => import('@/views/leaveRequestDetail/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveRequestDetail',
      resource: 'leave-request-detail',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveRequestDetail',
          active: true,
        },
      ],
    },
  },
]
