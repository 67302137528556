import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/leave-request`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload, [], "AND", false);
    if (payload.needMyApproval == 1) {
      let groupQueries = [];
      groupQueries.push({
        filters: [
          {
            searchby: "state",
            searchoperator: "=",
            search: 3,
          },
          {
            searchby: "leave_verify_id",
            searchoperator: "=",
            search: payload.userId,
          },
          ...params.filters[0].filters,
        ],
        filtertype: "AND",
      });
      groupQueries.push({
        filters: [
          {
            searchby: "state",
            searchoperator: "=",
            search: 4,
          }, {
            searchby: "final_leave_approval_id",
            searchoperator: "=",
            search: payload.userId,
          },
          ...params.filters[0].filters,
        ],
        filtertype: "AND",
      });
      params.filters = [...groupQueries];
    }
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  export(payload, onDownloadProgress) {
    let params = prepareFilterParams(payload, [], "AND", false);
    if (payload.needMyApproval == 1) {
      let groupQueries = [];
      groupQueries.push({
        filters: [
          {
            searchby: "state",
            searchoperator: "=",
            search: 3,
          },
          {
            searchby: "leave_verify_id",
            searchoperator: "=",
            search: payload.userId,
          },
          ...params.filters[0].filters,
        ],
        filtertype: "AND",
      });
      groupQueries.push({
        filters: [
          {
            searchby: "state",
            searchoperator: "=",
            search: 4,
          }, {
            searchby: "final_leave_approval_id",
            searchoperator: "=",
            search: payload.userId,
          },
          ...params.filters[0].filters,
        ],
        filtertype: "AND",
      });
      params.filters = [...groupQueries];
    }
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
      onDownloadProgress,
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show",
      },
    });
  },
  approve(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "approve",
      },
    });
  },
  reject(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "reject",
      },
    });
  },
};
