export default [
  {
    path: '/holiday',
    name: 'list-holiday',
    component: () => import('@/views/holiday/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.holiday',
      resource: 'holiday',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listHoliday',
          active: true,
        },
      ],
    },
  },
  {
    path: '/holiday/create',
    name: 'create-holiday',
    component: () => import('@/views/holiday/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.holiday',
      navActiveLink: 'list-holiday',
      resource: 'holiday',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listHoliday',
          to: { name: 'list-holiday' }
        },
        {
          text: 'breadcrumb.createHoliday',
          active: true,
        },
      ],
    },
  },
  {
    path: '/holiday/:id/update',
    name: 'update-holiday',
    component: () => import('@/views/holiday/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.holiday',
      navActiveLink: 'list-holiday',
      resource: 'holiday',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listHoliday',
          to: { name: 'list-holiday' }
        },
        {
          text: 'breadcrumb.updateHoliday',
          active: true,
        },
      ],
    },
  },
]
