export default [
  {
    path: '/product-display',
    name: 'list-product-display',
    component: () => import('@/views/productDisplay/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.productDisplay',
      resource: 'product-display',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductDisplay',
          active: true,
        },
      ],
    },
  },
]
