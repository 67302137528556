export default [
  {
    path: '/mass-branding',
    name: 'list-mass-branding',
    component: () => import('@/views/massBranding/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.massBranding',
      resource: 'mass-branding',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listMassBranding',
          active: true,
        },
      ],
    },
  },
]
