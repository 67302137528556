export default [
  {
    path: '/order-summary-report',
    name: 'order-summary-report',
    component: () => import('@/views/report/orderSummary/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.orderSummaryReport',
      resource: 'order-summary-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.orderSummaryReport',
          active: true,
        },
      ],
    },
  },
  {
    path: '/outlet-registration-report',
    name: 'outlet-registration-report',
    component: () => import('@/views/report/outletRegistration/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletRegistrationReport',
      resource: 'outlet-registration-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.outletRegistrationReport',
          active: true,
        },
      ],
    },
  },
  {
    path: '/active-outlet-report',
    name: 'active-outlet-report',
    component: () => import('@/views/report/activeOutlet/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.activeOutletReport',
      resource: 'active-outlet-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.activeOutletReport',
          active: true,
        },
      ],
    },
  },
]
