<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    v-model="isShow"
    @hide="checkIsCanClose"
  >
    <h4 class="mb-2">
      {{ $t("general.pleaseResetPassword") }}
    </h4>
    <validation-observer ref="updatePasswordForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="updatePasswordForm"
        :form="$refs.updatePasswordForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="fields" v-model="data"> </n-input>
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button
              type="button"
              variant="danger"
              class="mr-1"
              @click="logout"
            >
              {{ $t("button.logout") }}
            </b-button>
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
            >
              {{ $t("button.save") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import { initialAbility } from "@/libs/acl/config";
import OrderSidebar from "@/navigation/vertical/menu/order";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import { logout } from "@/auth/utils";

const ProfileRepository = Repository.get("profile");
const OrderRepository = Repository.get("order");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    NFormConfirmation,
    NInput,
    NButtonLoading,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  watch: {
    pendingOrder(value) {
      OrderSidebar[0].tag = value > 99 ? "99+" : value;
    },
  },
  data() {
    return {
      pendingOrder: 0,
      isShow: false,
      loading: false,
      fields: [
        {
          key: "oldPassword",
          placeholder: "placeholder.password",
          label: "field.oldPassword",
          rules: "required",
          type: "password",
          cols: 12,
        },
        {
          key: "newPassword",
          placeholder: "placeholder.password",
          label: "field.newPassword",
          rules: "required|min:6",
          type: "password",
          cols: 12,
        },
        {
          key: "retypeNewPassword",
          label: "field.retypeNewPassword",
          placeholder: "placeholder.password",
          rules: "required|confirmed:newPassword",
          type: "password",
          cols: 12,
        },
      ],
      data: {
        oldPassword: "",
        newPassword: "",
        retypeNewPassword: "",
      },
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    logout() {
      logout();
      this.$router.push({ name: "login" });
    },
    checkIsCanClose(bvModalEvent) {
      if (this.userData.isForceResetPassword) {
        bvModalEvent.preventDefault();
        this.isShow = true;
      }
    },
    submit() {
      this.$refs.updatePasswordForm.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    save() {
      this.loading = true;
      ProfileRepository.updatePassword(this.data)
        .then((response) => {
          this.getProfile();
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$refs.updatePasswordForm.setErrors(
              error.response?.data?.message
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getProfile() {
      ProfileRepository.getProfile().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.$store.commit("profile/UPDATE_PROFILE", data);
          localStorage.setItem("userData", JSON.stringify(data));
          let newAbility = [...initialAbility];
          if (data.ability) {
            if (data.isReadonly) {
              data.ability.forEach((x) => {
                x.action = "read";
                return x;
              });
            }
            newAbility = [...data.ability, ...initialAbility];
          }
          // Updating user ability in CASL plugin instance
          this.$ability.update([]);
          this.$ability.update(newAbility);
          this.isShow = data.isForceResetPassword;
        }
      });
    },
  },
};
</script>
