export default [
  {
    path: '/region',
    name: 'list-region',
    component: () => import('@/views/region/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.region',
      resource: 'region',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRegion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/region/:id/update',
    name: 'update-region',
    component: () => import('@/views/region/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.region',
      navActiveLink: 'list-region',
      resource: 'region',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listRegion',
          to: { name: 'list-region' }
        },
        {
          text: 'breadcrumb.updateRegion',
          active: true,
        },
      ],
    },
  },
]
