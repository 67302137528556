export default [
  {
    path: '/survey',
    name: 'list-survey',
    component: () => import('@/views/survey/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.survey',
      resource: 'survey',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSurvey',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/create',
    name: 'create-survey',
    component: () => import('@/views/survey/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.survey',
      navActiveLink: 'list-survey',
      resource: 'survey',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listSurvey',
          to: { name: 'list-survey' }
        },
        {
          text: 'breadcrumb.createSurvey',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/:id/update',
    name: 'update-survey',
    component: () => import('@/views/survey/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.survey',
      navActiveLink: 'list-survey',
      resource: 'survey',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSurvey',
          to: { name: 'list-survey' }
        },
        {
          text: 'breadcrumb.updateSurvey',
          active: true,
        },
      ],
    },
  },
]
