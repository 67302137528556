export default [
  {
    path: '/outlet',
    name: 'list-outlet',
    component: () => import('@/views/outlet/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.outlet',
      resource: 'outlet',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutlet',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/outlet/transfer',
  //   name: 'transfer-outlet',
  //   component: () => import('@/views/outlet/Transfer/Index.vue'),
  //   meta: {
  //     pageTitle: 'breadcrumb.outlet',
  //     navActiveLink: 'list-outlet',
  //     resource: 'outlet',
  //     action: 'transfer',
  //     breadcrumb: [
  //       {
  //         text: 'breadcrumb.listOutlet',
  //         to: { name: 'list-outlet' }
  //       },
  //       {
  //         text: 'breadcrumb.transferOutlet',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/outlet/create',
  //   name: 'create-outlet',
  //   component: () => import('@/views/outlet/Create.vue'),
  //   meta: {
  //     pageTitle: 'breadcrumb.outlet',
  //     navActiveLink: 'list-outlet',
  //     resource: 'outlet',
  //     action: 'create',
  //     breadcrumb: [
  //       {
  //         text: 'breadcrumb.listOutlet',
  //         to: { name: 'list-outlet' }
  //       },
  //       {
  //         text: 'breadcrumb.createOutlet',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/outlet/:id/update',
    name: 'update-outlet',
    component: () => import('@/views/outlet/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.outlet',
      navActiveLink: 'list-outlet',
      resource: 'outlet',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutlet',
          to: { name: 'list-outlet' }
        },
        {
          text: 'breadcrumb.updateOutlet',
          active: true,
        },
      ],
    },
  },
]
