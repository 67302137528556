export default [
  {
    path: '/no-order-reason',
    name: 'list-no-order-reason',
    component: () => import('@/views/noOrderReason/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.noOrderReason',
      resource: 'no-order-reason',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listNoOrderReason',
          active: true,
        },
      ],
    },
  },
  {
    path: '/no-order-reason/create',
    name: 'create-no-order-reason',
    component: () => import('@/views/noOrderReason/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.noOrderReason',
      navActiveLink: 'list-no-order-reason',
      resource: 'no-order-reason',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listNoOrderReason',
          to: { name: 'list-no-order-reason' }
        },
        {
          text: 'breadcrumb.createNoOrderReason',
          active: true,
        },
      ],
    },
  },
  {
    path: '/no-order-reason/:id/update',
    name: 'update-no-order-reason',
    component: () => import('@/views/noOrderReason/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.noOrderReason',
      navActiveLink: 'list-no-order-reason',
      resource: 'no-order-reason',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listNoOrderReason',
          to: { name: 'list-no-order-reason' }
        },
        {
          text: 'breadcrumb.updateNoOrderReason',
          active: true,
        },
      ],
    },
  },
]
