import { $themeConfig } from '@themeConfig'
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: localStorage.getItem("menu") == undefined ? $themeConfig.layout.menu.isCollapsed : localStorage.getItem("menu") == 'true',
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      localStorage.setItem("menu", val)
      state.isVerticalMenuCollapsed = val
    },
  },
  actions: {},
}
