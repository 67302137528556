import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import profile from './profile'
import pagination from './pagination'
import column from './column'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'app',
  storage: localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    profile,
    pagination,
    column,
  },
  plugins: [vuexPersist.plugin],
  strict: process.env.DEV,
})
