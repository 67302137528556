<template>
  <v-select
    :key="`multi-select-${key}`"
    v-model="selected"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    multiple
    :label="localization ? selectionLabelLocalize : selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="option"
    :loading="loading"
    :reduce="(item) => item[selectionKey]"
    @search="getOption"
    @open="open"
    class="n-async-multi-select multi-select"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template #no-options>
      {{ $t("field.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";
import { camelize } from "humps";

import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    value: {},
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    repository: {},
    selectionKey: {
      type: String,
      default: "id",
    },
    selectionLabel: {
      type: String,
      default: "name",
    },
    filterField: {
      type: String,
      default: "",
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    localization: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 20,
    },
    initOptions: {
      default: function () {
        return [];
      },
    },
    query: {
      type: Array,
      default: function () {
        return [];
      },
    },
    queryCondition: {
      type: String,
      default: "AND",
    },
  },
  data() {
    return {
      key: 1,
      option: [],
      selected: [],
      Repository: null,
      loading: false,
      loadedInitOptions: false,
      firstLoadOption: false,
    };
  },
  computed: {
    selectionLabelLocalize() {
      return camelize(`${this.selectionLabel}_${this.$i18n.locale}`);
    },
  },
  watch: {
    value() {
      if (this.value == null || this.value == []) {
        this.selected = [];
      }
    },
    selected: function (value) {
      this.$emit("input", value);
    },
    initOptions: function (value) {
      if (!this.loadedInitOptions && value.length) {
        this.getOption();
      }
    },
  },
  created() {
    this.Repository = RepositoryFactory.get(this.repository);
  },
  methods: {
    open() {
      if (!this.option.length) {
        this.getOption();
      }
    },
    mapExcludeIds() {
      let excludeIds = [...this.option];

      return excludeIds.map((item) => {
        return item[this.selectionKey];
      });
    },
    mergeArray(arrayA, arrayB) {
      return arrayA.concat(arrayB).reduce((accumulator, currentValue) => {
        if (
          !accumulator.some((item) => {
            return item[this.selectionKey] === currentValue[this.selectionKey];
          })
        ) {
          accumulator.push(currentValue);
        }

        return accumulator;
      }, []);
    },
    getOption(search = null) {
      this.loading = true;
      const excludeIds = this.mapExcludeIds();

      this.Repository.options({
        limit: this.limit,
        offset: 0,
        search: search ? search : "",
        includeIds: this.loadedInitOptions
          ? ""
          : Array.isArray(this.initOptions)
          ? this.initOptions.join(",")
          : this.initOptions,
        excludeIds: this.loadedInitOptions ? excludeIds.join(",") : "",
        filterField: this.filterField,
        query: this.query,
        queryCondition: this.queryCondition,
      })
        .then((response) => {
          if (response?.data?.data?.list) {
            this.option = this.mergeArray(this.option, response.data.data.list);
          } else {
            this.option = this.mergeArray(this.option, response.data.data);
          }

          if (!this.loadedInitOptions) {
            if (this.initOptions) {
              let selected = [];
              this.option.forEach((element) => {
                if (Array.isArray(this.initOptions)) {
                  for (let i = 0; i < this.initOptions.length; i++) {
                    if (this.initOptions[i] == element[this.selectionKey]) {
                      selected.push(element[this.selectionKey]);
                      break;
                    }
                  }
                } else {
                  if (this.initOptions == element[this.selectionKey]) {
                    selected.push(element[this.selectionKey]);
                  }
                }
              });
              this.selected = [...selected];
            }
          }
          this.loadedInitOptions = true;
          if (!this.firstLoadOption) {
            this.getOption();
            this.firstLoadOption = true;
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>