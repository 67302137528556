import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/product-review`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  updateStatus(id, isEnable) {
    const params = {
      id: id,
      isEnable: isEnable,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update-status",
      },
    });
  },
};
