export default [
  {
    path: '/salesperson',
    name: 'list-salesperson',
    component: () => import('@/views/salesperson/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesperson',
      resource: 'salesperson',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSalesperson',
          active: true,
        },
      ],
    },
  },
  {
    path: '/salesperson/create',
    name: 'create-salesperson',
    component: () => import('@/views/salesperson/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesperson',
      navActiveLink: 'list-salesperson',
      resource: 'salesperson',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listSalesperson',
          to: { name: 'list-salesperson' }
        },
        {
          text: 'breadcrumb.createSalesperson',
          active: true,
        },
      ],
    },
  },
  {
    path: '/salesperson/:id/update',
    name: 'update-salesperson',
    component: () => import('@/views/salesperson/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesperson',
      navActiveLink: 'list-salesperson',
      resource: 'salesperson',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSalesperson',
          to: { name: 'list-salesperson' }
        },
        {
          text: 'breadcrumb.updateSalesperson',
          active: true,
        },
      ],
    },
  },
]
