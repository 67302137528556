export default [
  {
    path: '/news',
    name: 'list-news',
    component: () => import('@/views/news/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.news',
      resource: 'news',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listNews',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/create',
    name: 'create-news',
    component: () => import('@/views/news/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.news',
      navActiveLink: 'list-news',
      resource: 'news',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listNews',
          to: { name: 'list-news' }
        },
        {
          text: 'breadcrumb.createNews',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/:id/update',
    name: 'update-news',
    component: () => import('@/views/news/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.news',
      navActiveLink: 'list-news',
      resource: 'news',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listNews',
          to: { name: 'list-news' }
        },
        {
          text: 'breadcrumb.updateNews',
          active: true,
        },
      ],
    },
  },
]
