export default [
  {
    path: '/product-category',
    name: 'list-product-category',
    component: () => import('@/views/productCategory/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.productCategory',
      resource: 'product-category',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-category/create',
    name: 'create-product-category',
    component: () => import('@/views/productCategory/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.productCategory',
      navActiveLink: 'list-product-category',
      resource: 'product-category',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductCategory',
          to: { name: 'list-product-category' }
        },
        {
          text: 'breadcrumb.createProductCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-category/:id/update',
    name: 'update-product-category',
    component: () => import('@/views/productCategory/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.productCategory',
      navActiveLink: 'list-product-category',
      resource: 'product-category',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductCategory',
          to: { name: 'list-product-category' }
        },
        {
          text: 'breadcrumb.updateProductCategory',
          active: true,
        },
      ],
    },
  },
]
