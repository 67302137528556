import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/call-plan`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  export(payload, onDownloadProgress) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
      onDownloadProgress,
    });
  },
  detail(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "detail",
      },
    });
  },
  showOrder(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show-order",
      },
    });
  },
  showCountStock(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show-count-stock",
      },
    });
  },
  showBranding(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show-branding",
      },
    });
  },
  showProductDisplay(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show-product-display",
      },
    });
  },
  showMarketSurvey(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show-market-survey",
      },
    });
  },
};
