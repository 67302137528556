export default [
  {
    path: '/product-brand',
    name: 'list-product-brand',
    component: () => import('@/views/productBrand/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.productBrand',
      resource: 'product-brand',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductBrand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-brand/create',
    name: 'create-product-brand',
    component: () => import('@/views/productBrand/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.productBrand',
      navActiveLink: 'list-product-brand',
      resource: 'product-brand',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductBrand',
          to: { name: 'list-product-brand' }
        },
        {
          text: 'breadcrumb.createProductBrand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-brand/:id/update',
    name: 'update-product-brand',
    component: () => import('@/views/productBrand/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.productBrand',
      navActiveLink: 'list-product-brand',
      resource: 'product-brand',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listProductBrand',
          to: { name: 'list-product-brand' }
        },
        {
          text: 'breadcrumb.updateProductBrand',
          active: true,
        },
      ],
    },
  },
]
