export default [
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('@/views/attendance/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.attendance',
      resource: 'attendance',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.attendance',
          active: true,
        },
      ],
    },
  },
]
