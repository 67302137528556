import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/claim-request`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  export(payload, onDownloadProgress) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "export",
      },
      responseType: "blob",
      onDownloadProgress,
    });
  },
};
