export default [
  {
    path: '/working-schedule',
    name: 'list-working-schedule',
    component: () => import('@/views/workingSchedule/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.workingSchedule',
      resource: 'working-schedule',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listWorkingSchedule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/working-schedule/create',
    name: 'create-working-schedule',
    component: () => import('@/views/workingSchedule/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.workingSchedule',
      navActiveLink: 'list-working-schedule',
      resource: 'working-schedule',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listWorkingSchedule',
          to: { name: 'list-working-schedule' }
        },
        {
          text: 'breadcrumb.createWorkingSchedule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/working-schedule/:id/update',
    name: 'update-working-schedule',
    component: () => import('@/views/workingSchedule/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.workingSchedule',
      navActiveLink: 'list-working-schedule',
      resource: 'working-schedule',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listWorkingSchedule',
          to: { name: 'list-working-schedule' }
        },
        {
          text: 'breadcrumb.updateWorkingSchedule',
          active: true,
        },
      ],
    },
  },
]
