export default [
  {
    path: '/leave-request',
    name: 'list-leave-request',
    component: () => import('@/views/leaveRequest/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveRequest',
      resource: 'leave-request',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveRequest',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leave-request/:id',
    name: 'view-leave-request',
    component: () => import('@/views/leaveRequest/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveRequest',
      navActiveLink: 'list-leave-request',
      resource: 'leave-request',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveRequest',
          to: { name: 'list-leave-request' }
        },
        {
          text: 'breadcrumb.leaveRequestDetail',
          active: true,
        },
      ],
    },
  },
]
