export default [
  {
    path: '/leave-type',
    name: 'list-leave-type',
    component: () => import('@/views/leaveType/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveType',
      resource: 'leave-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leave-type/:id/update',
    name: 'update-leave-type',
    component: () => import('@/views/leaveType/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveType',
      navActiveLink: 'list-leave-type',
      resource: 'leave-type',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveType',
          to: { name: 'list-leave-type' }
        },
        {
          text: 'breadcrumb.updateLeaveType',
          active: true,
        },
      ],
    },
  },
]
