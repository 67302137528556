export default [
  {
    path: '/outlet-draft',
    name: 'list-outlet-draft',
    component: () => import('@/views/outletDraft/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletDraft',
      resource: 'outlet-draft',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutletDraft',
          active: true,
        },
      ],
    },
  },
  {
    path: '/outlet-draft/:id',
    name: 'view-outlet-draft',
    component: () => import('@/views/outletDraft/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletDraft',
      navActiveLink: 'list-outlet-draft',
      resource: 'outlet-draft',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutletDraft',
          to: { name: 'list-outlet-draft' }
        },
        {
          text: 'breadcrumb.outletDraftDetail',
          active: true,
        },
      ],
    },
  },
]
