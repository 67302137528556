import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/salesperson`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  export(payload, onDownloadProgress) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
      onDownloadProgress,
    });
  },
  search(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "search",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "name",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "id_card",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries, "OR");
    if (payload.query.length) {
      let filters = {
        filters: [],
        filtertype: payload.queryCondition,
      };
      payload.query.forEach(element => {
        filters.filters.push(element);
      });
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
