var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.value),function(data,idx){return _c('b-row',{key:("row-" + idx),class:{
      'saperate-link': idx > 0,
      'pt-1': idx > 0,
    }},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"vid":("name-km-" + idx),"name":"field.nameKm","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("field.nameKm")))]),_c('b-form-input',{ref:("name-km-" + idx),refInFor:true,class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":("name-km-" + idx),"placeholder":_vm.$t('field.nameKm'),"autocomplete":"off"},model:{value:(data.nameKm),callback:function ($$v) {_vm.$set(data, "nameKm", $$v)},expression:"data.nameKm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"vid":("name-en-" + idx),"name":"field.nameEn","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("field.nameEn")))]),_c('b-form-input',{ref:("name-en-" + idx),refInFor:true,class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":("name-en-" + idx),"placeholder":_vm.$t('field.nameEn'),"autocomplete":"off"},model:{value:(data.nameEn),callback:function ($$v) {_vm.$set(data, "nameEn", $$v)},expression:"data.nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('b-form-group',[_c('label',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.$t("field.file"))+" "),_c('b-link',{staticClass:"link-underline float-right text-danger",on:{"click":function($event){return _vm.removeRow(idx)}}},[_vm._v(" "+_vm._s(_vm.$t("button.remove"))+" ")])],1),_c('validation-provider',{attrs:{"vid":("media-" + idx),"name":"field.file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('n-file-uploader',{ref:("file" + idx),refInFor:true,attrs:{"type":"file","name":("file-" + idx),"state":errors.length > 0 ? false : null,"browse-text":_vm.$t('button.browse'),"placeholder":_vm.$t('button.chooseFileOrDropHere'),"drop-placeholder":_vm.$t('button.dropHere'),"item":data},on:{"change":function($event){return _vm.onFileChange($event, idx)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.mediaId),expression:"data.mediaId"}],attrs:{"name":("media-" + idx),"type":"hidden"},domProps:{"value":(data.mediaId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "mediaId", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"block":"","variant":"primary","size":"sm"},on:{"click":_vm.addNewRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }