export default [
  {
    path: '/outlet-segment',
    name: 'list-outlet-segment',
    component: () => import('@/views/outletSegment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletSegment',
      resource: 'outlet-segment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutletSegment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/outlet-segment/create',
    name: 'create-outlet-segment',
    component: () => import('@/views/outletSegment/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletSegment',
      navActiveLink: 'list-outlet-segment',
      resource: 'outlet-segment',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutletSegment',
          to: { name: 'list-outlet-segment' }
        },
        {
          text: 'breadcrumb.createOutletSegment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/outlet-segment/:id/update',
    name: 'update-outlet-segment',
    component: () => import('@/views/outletSegment/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.outletSegment',
      navActiveLink: 'list-outlet-segment',
      resource: 'outlet-segment',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listOutletSegment',
          to: { name: 'list-outlet-segment' }
        },
        {
          text: 'breadcrumb.updateOutletSegment',
          active: true,
        },
      ],
    },
  },
]
