import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/libs/i18n";
import { $themeConfig } from "@themeConfig";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import errors from "./routes/errors";
import auth from "./routes/auth";
import profile from "./routes/profile";
import user from "./routes/user";
import role from "./routes/role";
import region from "./routes/region";
import agent from "./routes/agent";
import outlet from "./routes/outlet";
import outletSegment from "./routes/outletSegment";
import productCategory from "./routes/productCategory";
import productBrand from "./routes/productBrand";
import product from "./routes/product";
import paymentMethod from "./routes/paymentMethod";
import order from "./routes/order";
import position from "./routes/position";
import salesperson from "./routes/salesperson";
import report from "./routes/report";
import attendance from "./routes/attendance";
import attendanceGroup from "./routes/attendanceGroup";
import activity from "./routes/activity";
import activityTravel from "./routes/activityTravel";
import configuration from "./routes/configuration";
import workingSchedule from "./routes/workingSchedule";
import item from "./routes/item";
import noOrderReason from "./routes/noOrderReason";
import newsCategory from "./routes/newsCategory";
import news from "./routes/news";
import outletDraft from "./routes/outletDraft";
import callPlan from "./routes/callPlan";
import productDisplay from "./routes/productDisplay";
import countStock from "./routes/countStock";
import massBranding from "./routes/massBranding";
import leaveType from "./routes/leaveType";
import leaveBalance from "./routes/leaveBalance";
import callPlanOrder from "./routes/callPlanOrder";
import holiday from "./routes/holiday";
import department from "./routes/department";
import leaveRequest from "./routes/leaveRequest";
import survey from "./routes/survey";
import marketSurvey from "./routes/marketSurvey";
import leaveRequestDetail from "./routes/leaveRequestDetail";
import outletFeedbackSurvey from "./routes/outletFeedbackSurvey";
import syncErpData from "./routes/syncErpData";
import claimRequest from "./routes/claimRequest";
import orderAutomate from "./routes/orderAutomate";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/callPlanDashboard/Index.vue"),
      meta: {
        pageTitle: "breadcrumb.dashboard",
        resource: "dashboard",
        breadcrumb: [
          {
            text: "breadcrumb.dashboard",
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...region,
    ...agent,
    ...outlet,
    ...outletSegment,
    ...productCategory,
    ...productBrand,
    ...product,
    ...paymentMethod,
    ...order,
    ...position,
    ...salesperson,
    ...report,
    ...attendance,
    ...attendanceGroup,
    ...activity,
    ...activityTravel,
    ...configuration,
    ...workingSchedule,
    ...item,
    ...noOrderReason,
    ...newsCategory,
    ...news,
    ...outletDraft,
    ...callPlan,
    ...productDisplay,
    ...countStock,
    ...massBranding,
    ...leaveType,
    ...leaveBalance,
    ...callPlanOrder,
    ...holiday,
    ...department,
    ...leaveRequest,
    ...survey,
    ...marketSurvey,
    ...leaveRequestDetail,
    ...outletFeedbackSurvey,
    ...syncErpData,
    ...claimRequest,
    ...orderAutomate,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  Vue.nextTick(() => {
    const { appName } = $themeConfig.app;
    document.title = `${to.meta.title ? i18n.tc(to.meta.title) : i18n.tc(to.meta.pageTitle)} | ${i18n.tc(appName)}`;
  });

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({
        name: "login", query: {
          redirect: to.fullPath
        }
      })
    }

    // If logged in => not authorized
    return next({ name: "not-authorized" })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? "admin" : null))
  }

  return next()
});

export default router;
