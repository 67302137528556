export default [
  {
    path: '/order-automate',
    name: 'list-order-automate',
    component: () => import('@/views/orderAutomate/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.orderAutomate',
      resource: 'order-automate',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listOrderAutomate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-automate/create',
    name: 'create-order-automate',
    component: () => import('@/views/orderAutomate/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.orderAutomate',
      navActiveLink: 'list-order-automate',
      resource: 'order-automate',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listOrderAutomate',
          to: { name: 'list-order-automate' }
        },
        {
          text: 'breadcrumb.createOrderAutomate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-automate/:id/update',
    name: 'update-order-automate',
    component: () => import('@/views/orderAutomate/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.orderAutomate',
      navActiveLink: 'list-order-automate',
      resource: 'order-automate',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listOrderAutomate',
          to: { name: 'list-order-automate' }
        },
        {
          text: 'breadcrumb.updateOrderAutomate',
          active: true,
        },
      ],
    },
  },
]
