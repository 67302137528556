export default [
  {
    path: '/count-stock',
    name: 'list-count-stock',
    component: () => import('@/views/countStock/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.countStock',
      resource: 'count-stock',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCountStock',
          active: true,
        },
      ],
    },
  },
]
