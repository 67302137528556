export default [
  {
    path: '/sync-erp-data',
    name: 'sync-erp-data',
    component: () => import('@/views/syncErpData/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.syncErpData',
      resource: 'sync-erp-data',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.syncErpData',
          active: true,
        },
      ],
    },
  },
]
