import moment from 'moment';
import { decamelize, camelize } from 'humps';
import store from '@/store';
import i18n from "@/libs/i18n";

export const caclulateOffset = (page, limit) => {
  return (page - 1) * limit;
};

export const calculateDatesByPeriod = (period) => {
  if (period === 'today') {
    return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else if (period === 'yesterday') {
    return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'thisWeek') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastWeek') {
    return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'thisMonth') {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'tomorrow') {
    return [moment().add(1, 'day').format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'nextWeek') {
    return [moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'thisYear') {
    return [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')];
  }

  return [];
}

export const prepareSearchParams = (payload, filtertype = "AND") => {
  let filterArr = [];

  if (payload.searchFields) {
    payload.searchFields.forEach(item => {
      if (payload[item.key] || false) {
        let search = payload[item.key];
        switch (item.cast) {
          case "boolean":
            search = (search == 1 ? true : false);
            break;
        }

        filterArr.push({
          searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
          searchoperator: item.operator || "=",
          search: search,
        });
      }
    });
  }
  const params = {
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    filtertype: filtertype,
    filters: filterArr,
  };

  return params;
}

export const prepareListOptionParams = (payload) => {
  let params = {
    limit: 25,
    sortby: "id",
    sort: "asc",
    filtertype: "AND",
    filters: [],
  };
  if (payload.includeIds) {
    params.filters.push({
      searchby: "id",
      searchoperator: "in",
      search: payload.includeIds.split(",")
    });
  } else {
    if (payload.excludeIds) {
      params.filters.push({
        searchby: "id",
        searchoperator: "notin",
        search: payload.excludeIds.split(",")
      });
    }
  }

  return params;
}

// export const prepareFilterParams = (payload, queries = [], filtertype = "AND") => {
//   let filterArr = [...queries];

//   if (payload.searchFields) {
//     payload.searchFields.forEach(item => {
//       if (item.cast == "range") {
//         if (payload[item.key] || false) {
//           if (payload[item.key].length == 2) {
//             let search = payload[item.key];
//             if (search[0]) {
//               filterArr.push({
//                 searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
//                 searchoperator: item.firstOperator || ">=",
//                 search: item.firstSuffix ? `${search[0]} ${item.firstSuffix}` : search[0],
//               });
//             }
//             if (search[1]) {
//               filterArr.push({
//                 searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
//                 searchoperator: item.secondOperator || "<=",
//                 search: item.secondSuffix ? `${search[1]} ${item.secondSuffix}` : search[1],
//               });
//             }
//           }
//         }
//       } else {
//         if (!item.skip) {
//           if (payload[item.key] || false) {
//             let search = payload[item.key];
//             if (item.operator == "in" && Array.isArray(search) && search.length > 0) {
//               filterArr.push({
//                 searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
//                 searchoperator: item.operator || "=",
//                 search: search,
//               });
//             } else if (item.operator == "in" && typeof search == "string") {
//               filterArr.push({
//                 searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
//                 searchoperator: item.operator,
//                 search: [search],
//               });
//             } else if (item.operator != "in") {
//               switch (item.cast) {
//                 case "boolean":
//                   search = (search == 1 ? true : false);
//                   break;
//               }

//               filterArr.push({
//                 searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
//                 searchoperator: item.operator || "=",
//                 search: search,
//               });
//             }
//           }
//         }
//       }
//     });
//   }

//   const params = {
//     limit: payload.limit ? payload.limit : store.state.pagination.perPage,
//     page: payload.page,
//     sortby: payload.order || "id",
//     sort: payload.sort || "desc",
//     filtertype: "AND",
//     filters: [{
//       filtertype: "AND",
//       filters: [{
//         searchby: "deleted_at",
//         searchoperator: payload.archived ? "!=" : "=",
//         search: null,
//       }],
//     }, {
//       filtertype: filtertype,
//       filters: filterArr,
//     }],
//   };

//   if (payload.query && payload.query.length) {
//     let filters = {
//       filters: [],
//       filtertype: "AND",
//     };
//     payload.query.forEach(element => {
//       filters.filters.push({
//         ...element,
//         searchby: decamelize(element.searchby),
//       });
//     });
//     params.filters.push(filters);
//   }

//   return params;
// }

export const prepareFilterParams = (payload, queries = [], filtertype = "AND", isDeletable = true) => {
  let filterArr = [...queries];

  if (payload?.searchFields) {
    payload.searchFields.forEach(item => {
      if (item.cast == "range") {
        if (!item.skip) {
          if (payload[item.key] || false) {
            if (payload[item.key].length == 2) {
              let search = payload[item.key];
              if (search[0]) {
                let searchField = decamelize(item.key);
                if (item.searchField) {
                  searchField = decamelize(item.searchField);
                }
                if (item.firstSearchField) {
                  searchField = decamelize(item.firstSearchField)
                }
                filterArr.push({
                  searchby: searchField,
                  searchoperator: item.firstOperator || ">=",
                  search: item.firstSuffix ? `${search[0]} ${item.firstSuffix}` : search[0],
                });
              }
              if (search[1]) {
                let searchField = decamelize(item.key);
                if (item.searchField) {
                  searchField = decamelize(item.searchField);
                }
                if (item.secondSearchField) {
                  searchField = decamelize(item.secondSearchField)
                }
                filterArr.push({
                  searchby: searchField,
                  searchoperator: item.secondOperator || "<=",
                  search: item.secondSuffix ? `${search[1]} ${item.secondSuffix}` : search[1],
                });
              }
            }
          }
        }
      } else {
        if (!item.skip) {
          if (payload[item.key] || false) {
            let search = payload[item.key];
            if (item.operator == "in" && typeof search == "string") {
              search = search.split(",");
            }
            if (item.operator != "in" || (item.operator == "in" && search.length)) {
              switch (item.cast) {
                case "boolean":
                  search = (search == 1 ? true : false);
                  break;
              }

              if (search && item.suffix) {
                search = `${search} ${item.suffix}`;
              }

              filterArr.push({
                searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
                searchoperator: item.operator || "=",
                search: search,
              });
            }
          }
        }
      }
    });
  }

  let params = {
    limit: payload.limit ? payload.limit : store.state.pagination.perPage,
    fields: payload?.fields ? payload.fields : [],
    page: payload?.page,
    sortby: payload?.order || "id",
    sort: payload?.sort || "desc",
    filtertype: "AND",
    filters: [{
      filtertype: filtertype,
      filters: filterArr,
    }],
  };
  if (isDeletable) {
    params.filters = [
      ...params.filters,
      {
        filtertype: "AND",
        filters: [{
          searchby: "deleted_at",
          searchoperator: payload?.archived ? "!=" : "=",
          search: null,
        }],
      }
    ]
  }

  if (payload.query && payload.query.length) {
    let filters = {
      filters: [],
      filtertype: "AND",
    };
    payload.query.forEach(element => {
      filters.filters.push({
        ...element,
        searchby: decamelize(element.searchby),
      });
    });
    params.filters.push(filters);
  }

  return params;
}

export const prepareFilterParamsNoDeleted = (payload, queries = [], filtertype = "AND") => {
  let filterArr = [...queries];

  if (payload?.searchFields) {
    payload.searchFields.forEach(item => {
      if (!item.skip) {
        if (payload[item.key] || false) {
          let search = payload[item.key];
          if (item.operator == "in" && typeof search == "string") {
            search = search.split(",");
          }
          if (item.operator != "in" || (item.operator == "in" && search.length)) {
            switch (item.cast) {
              case "boolean":
                search = (search == 1 ? true : false);
                break;
            }

            if (search && item.suffix) {
              search = `${search} ${item.suffix}`;
            }

            filterArr.push({
              searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
              searchoperator: item.operator || "=",
              search: search,
            });
          }
        }
      }
    });
  }

  let params = {
    limit: store.state.pagination.perPage,
    fields: payload?.fields ? payload.fields : [],
    page: payload?.page,
    sortby: payload?.order || "id",
    sort: payload?.sort || "desc",
    filtertype: "AND",
    filters: [{
      filtertype: filtertype,
      filters: filterArr,
    }],
  };

  if (payload.query && payload.query.length) {
    let filters = {
      filters: [],
      filtertype: "AND",
    };
    payload.query.forEach(element => {
      filters.filters.push({
        ...element,
        searchby: decamelize(element.searchby),
      });
    });
    params.filters.push(filters);
  }

  return params;
}

export const prepareFilterParamsLeaveRequest = (payload, groupQueries = []) => {
  let filterArr = [];
  if (payload?.searchFields) {
    groupQueries.forEach(group => {
      payload.searchFields.forEach(item => {
        if (!item.skip) {
          if (payload[item.key] || false) {
            let search = payload[item.key];
            if (item.operator == "in" && typeof search == "string") {
              search = search.split(",");
            }
            if (item.operator != "in" || (item.operator == "in" && search.length)) {
              switch (item.cast) {
                case "boolean":
                  search = (search == 1 ? true : false);
                  break;
              }

              if (search && item.suffix) {
                search = `${search} ${item.suffix}`;
              }

              group.filters.push({
                searchby: item.searchField ? decamelize(item.searchField) : decamelize(item.key),
                searchoperator: item.operator || "=",
                search: search,
              });
            }
          }
        }
      });
      filterArr.push(group);
    });
  }

  let params = {
    limit: store.state.pagination.perPage,
    fields: payload?.fields ? payload.fields : [],
    page: payload?.page,
    sortby: payload?.order || "id",
    sort: payload?.sort || "desc",
    filtertype: "OR",
    filters: [...filterArr],
  };

  return params;
}

export const prepareOptionFilterParams = (payload, queries = [], filtertype = "AND", sortby = "id", sort = "asc") => {
  let filters = [{
    searchby: "deleted_at",
    searchoperator: payload.achieved ? "!=" : "=",
    search: null,
  }];
  if (payload.includeIds) {
    filters.push({
      searchby: "id",
      searchoperator: "in",
      search: payload.includeIds.split(",")
    });
  } else {
    if (payload.excludeIds) {
      filters.push({
        searchby: "id",
        searchoperator: "notin",
        search: payload.excludeIds.split(",")
      });
    }
  }

  let params = {
    limit: payload.limit ? payload.limit : 25,
    sortby: sortby,
    sort: sort,
    filtertype: "AND",
    filters: [{
      filtertype: "AND",
      filters: filters,
    }, {
      filtertype: filtertype,
      filters: queries,
    }],
  };

  return params;
}

export const mapAbility = (data, newAbility, initialAbility) => {
  if (data.isReadonly) {
    data.ability.forEach((x) => {
      x.action = "read";
      return x;
    });
  }

  newAbility = [...data.ability, ...initialAbility];
  return newAbility;
}

export const textColor = (bgColor) => {
  // Get background color hex value. '#' is optional.
  let hex = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;

  // Convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // By this point, it should be 6 characters
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  // Return light or dark class based on contrast calculation
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? "text-dark"
    : "text-light";
};

export const formatPhoneNumber = (phoneNumber, to = "") => {
  return phoneNumber.replace("+855", to);
}

export const mapExportField = (columns, visibleColumns = [], isExportAll = false) => {
  let fields = [];
  columns.forEach((element) => {
    if (!element.skipExport && !element.hideToggle && (isExportAll || visibleColumns.includes(element.key))) {
      const key = element.exportKey ? element.exportKey : element.key;
      let options = [];
      if (element.options) {
        options = element.options.map(label => {
          return label ? i18n.t(label) : "";
        });
      }
      fields.push({
        field: element.localization ? camelize(`${key}_${i18n.locale}`) : key,
        label: i18n.t(element.label),
        width: element.width ? element.width : 5000,
        isImage: element.isImage ? element.isImage : false,
        options: options,
      });
    }
  });
  return fields;
}

export const mapAllExportField = (columns) => {
  let fields = [];
  columns.forEach((element) => {
    if (!element.skipExport && !element.hideToggle) {
      const key = element.exportKey ? element.exportKey : element.key;
      fields.push({
        field: element.localization ? camelize(`${key}_${i18n.locale}`) : key,
        label: i18n.t(element.label),
        width: element.width ? element.width : 5000,
        isImage: element.isImage ? element.isImage : false,
        isLink: element.isLink ? element.isLink : false,
      });
    }
  });
  return fields;
}


export const toMinutesSeconds = (totalSeconds) => {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const secondsStr = makeHumanReadable(seconds, "second");
  const minutesStr = makeHumanReadable(minutes, "minute");
  const hoursStr = makeHumanReadable(hours, "hour");
  const string = `${hoursStr}${minutesStr}${secondsStr}`.replace(
    /,\s*$/,
    ""
  );

  return string ? string : `0 ${i18n.tc("time.second", 0)}`;
}

function makeHumanReadable(num, singular) {
  return num > 0 ? `${num} ${i18n.tc(`time.${singular}`, num)} ` : "";
}